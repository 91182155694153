import { formatPrice2Digits } from '../Shared/PriceHelper';

declare var dataLayer: any[];
declare var __ucCmp: any;

/**
 * Googletracking für ein Promotionevent
 */
export async function productGoogleTracking() {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        let productsGoogleTrackingContainer = $('#productGoogleTracking');
        let name =
            productsGoogleTrackingContainer.data('name') !== '' ? productsGoogleTrackingContainer.data('name') : null;
        let id = productsGoogleTrackingContainer.data('id') !== '' ? productsGoogleTrackingContainer.data('id') : null;
        let price =
            productsGoogleTrackingContainer.data('price') !== ''
                ? formatPrice2Digits(parseFloat(productsGoogleTrackingContainer.data('price')))
                : null;
        let brand =
            productsGoogleTrackingContainer.data('brand') !== '' ? productsGoogleTrackingContainer.data('brand') : null;
        let category =
            productsGoogleTrackingContainer.data('category') !== ''
                ? productsGoogleTrackingContainer.data('category')
                : 'Produkt ohne Kategorie';
        let displayFormat =
            productsGoogleTrackingContainer.data('displayformat') !== ''
                ? productsGoogleTrackingContainer.data('displayformat')
                : null;
        let variant = null;
        if (productsGoogleTrackingContainer.data('producttype') == 'SEMINAR') {
            variant = 'Seminar/Tagung';
        } else {
            if (productsGoogleTrackingContainer.data('variant') !== '') {
                variant = productsGoogleTrackingContainer.data('variant');
            }
        }

        dataLayer.push({
            event: 'eec.productDetailImpression',
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: name, // Name or ID is required.
                            id: id,
                            price: price,
                            brand: brand,
                            category: category,
                            variant: variant,
                            dimension8: displayFormat,
                        },
                    ],
                },
            },
        });
    } else {
        setTimeout(productGoogleTracking, 100);
    }
}

export function availabilityReminderSubmitTracking(productId: string, eventAction: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: eventAction,
        eventLabel: `reminder_${productId}`,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', eventAction + '/' + `reminder_${productId}`, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}
