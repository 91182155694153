import * as Cookies from 'js-cookie';

declare var dataLayer: any[];
var windowHeight = $(window).innerHeight();
declare var _trboq: any;
declare var _trbo: any;
declare var __ucCmp: any;

/**
 * Googletracking für ein Promotionevent
 * @param eventName Der Name des Trackingevents
 */
export async function trackGooglePromotionEvent_view(eventName: string) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        dataLayer.push({
            event: 'eec.promotionView',
            ecommerce: {
                promoView: {
                    promotions: [
                        {
                            name: eventName,
                        },
                    ],
                },
            },
        });

        dataLayer.push({
            event: 'econdaEvent',
            ecCustomProperties: {
                icampv: [[eventName]],
                rqtype: 'hiddenpi',
            },
        });
    } else {
        setTimeout(trackGooglePromotionEvent_view.bind(null, eventName), 100);
    }
}
export function trackGooglePromotionEvent_click(eventName: string) {
    dataLayer.push({
        event: 'eec.promotionClick',
        ecommerce: {
            promoClick: {
                promotions: [
                    {
                        name: eventName,
                    },
                ],
            },
        },
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            icampc: [[eventName]],
            rqtype: 'hiddenpi',
        },
    });
}

export function trackGoogleAddToMerkliste_click(productId: number) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: 'add to wishlist',
        eventLabel: `add_${productId}`,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', 'add to wishlist' + '/' + `add_${productId}`, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function trackMerklisteInteraction_click(eventLabel: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'user data',
        eventAction: 'interaction_wishlist',
        eventLabel: eventLabel,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['user data', 'interaction_wishlist' + '/' + eventLabel, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export async function MerklisteKarusellInViewTracking() {
    await trackGooglePromotionEvent_view('all_merklisten_static');
}

export function MerklisteKarusellProductClickedTracking() {
    trackGooglePromotionEvent_click('all_merklisten_static');
}

export function trackNaviEvent_click(eventName: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'navigation data',
        eventAction: 'navigation_click',
        eventLabel: eventName,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['navigation data', 'navigation_click' + '/' + eventName, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export async function loginTracking(pageType: string, loggedInResult: string) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        if (pageType.includes('meine-daten')) {
            dataLayer.push({
                event: 'event',
                eventCategory: 'user data',
                eventAction: 'login_meine-daten',
                eventLabel: loggedInResult == 'failed' ? 'login_failed' : 'login_success',
            });

            dataLayer.push({
                event: 'econdaEvent',
                ecCustomProperties: {
                    Target: [
                        'user data',
                        'login_meine-daten' + '/' + (loggedInResult == 'failed' ? 'login_failed' : 'login_success'),
                        0,
                        'd',
                    ],
                    rqtype: 'hiddenpi',
                },
            });
        } else {
            dataLayer.push({
                event: 'event',
                eventCategory: 'user data',
                eventAction: 'login_checkout',
                eventLabel: loggedInResult == 'failed' ? 'login_failed' : 'login_success',
            });

            dataLayer.push({
                event: 'econdaEvent',
                ecCustomProperties: {
                    Target: [
                        'user data',
                        'login_checkout' + '/' + (loggedInResult == 'failed' ? 'login_failed' : 'login_success'),
                        0,
                        'd',
                    ],
                    rqtype: 'hiddenpi',
                },
            });
        }
        Cookies.remove('LoggedInPageType');
        Cookies.remove('LoggedInResult');
    } else {
        setTimeout(loginTracking.bind(null, pageType, loggedInResult), 100);
    }
}

export async function pushTrboId(eId: string) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        _trboq = _trboq || [];
        _trboq.push([
            'dataMatching',
            {
                external_user_id: eId,
            },
        ]);
    } else {
        setTimeout(pushTrboId.bind(null, eId), 100);
    }
}

export async function trackIdLayerComplete(hId: string, hMail: string, hKid: string) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        dataLayer.push({
            event: 'idLayer_complete_login',
            externalIdHash: hId,
            emailHash: hMail,
            kupCustomerNumberHash: hKid,
        });
    } else {
        setTimeout(trackIdLayerComplete.bind(null, hId, hMail, hKid), 100);
    }
}

export async function trackIdLayerReduced(hMail: string, eventName: string) {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        dataLayer.push({
            event: eventName,
            emailHash: hMail,
        });
    } else {
        setTimeout(trackIdLayerReduced.bind(null, hMail, eventName), 100);
    }
}

export function trackNewsletterRegisterEvent(eventName: string) {
    if (eventName) {
        dataLayer.push({
            event: 'event',
            eventCategory: 'user data',
            eventAction: 'newsletter_registrierung',
            eventLabel: eventName,
        });

        dataLayer.push({
            event: 'econdaEvent',
            ecCustomProperties: {
                Target: ['user data', 'newsletter_registrierung' + '/' + eventName, 0, 'd'],
                rqtype: 'hiddenpi',
            },
        });
    }
}

export async function tracking_eecPromotionview() {
    const promotionElements = $('.googleTracking.promotionTracking:not(.promoted)');
    promotionElements.each(function() {
        if (IsVisibleElement(this)) {
            const trackingParam = $(this).data('trackingevent');
            if (trackingParam) {
                trackGooglePromotionEvent_view(trackingParam);
            }
            $(this).addClass('promoted');
        }
    });

    await trboPromotionViewTracking();
}

async function trboPromotionViewTracking() {
    if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized())) {
        const promotionTrboElements = $('.trboPromotionTracking:not(.promoted)');
        promotionTrboElements.each(function() {
            if (IsVisibleElement(this)) {
                const moduleId = $(this).data('moduleid');
                const moduleName = $(this).data('modulename');
                const campaignId = $(this).data('campaignid');
                const campaignName = $(this).data('campaignname');
                const usertype = $(this).data('usertype');

                if (window['_trboq'] !== undefined && window['_trbo'] !== undefined) {
                    _trboq.push(['layerView', { moduleId: moduleId, campaignId: campaignId }]);
                    _trbo.app.tracker.trackExternalView(usertype, campaignId, campaignName, moduleId, moduleName);
                    $(this).addClass('promoted');
                }
            }
        });
    } else {
        setTimeout(trboPromotionViewTracking, 100);
    }
}

export function trackExternalBasketClick(productId: number, eventLabel: string, eventAction: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'product data',
        eventAction: eventAction,
        eventLabel: `${eventLabel}_${productId}`,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['product data', 'newsletter_registrierung' + '/' + `${eventLabel}_${productId}`, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function trackPrefixNameProblem() {
    dataLayer.push({
        event: 'event',
        eventCategory: 'user data',
        eventAction: 'interaction_forms',
        eventLabel: 'problem_name-prefix',
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['user data', 'interaction_forms' + '/' + 'problem_name-prefix', 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function trackLageRegistrationAccount(action: string, label: string) {
    dataLayer.push({
        event: 'event',
        eventCategory: 'user data',
        eventAction: action,
        eventLabel: label,
    });

    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            Target: ['user data', action + '/' + label, 0, 'd'],
            rqtype: 'hiddenpi',
        },
    });
}

export function searchResultClickTracking(searchQuery: string) {
    dataLayer.push({
        event: 'econdaEvent',
        ecCustomProperties: {
            icampc: [[searchQuery ? `Suchergebnis/${searchQuery}` : 'Suchergebnis']],
            rqtype: 'hiddenpi',
        },
    });
}

function IsVisibleElement(element) {
    if ($(element).hasClass('hide')) {
        return false;
    }
    var top_of_element = $(element).offset().top;
    var bottom_of_element = $(element).offset().top + $(element).outerHeight();
    var top_of_screen = $(window).scrollTop();
    var bottom_of_screen = top_of_screen + windowHeight;

    if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
        return true;
    } else {
        return false;
    }
}
