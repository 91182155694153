import { ProduktPrice } from './productprice';
import { TabsRenderer } from './TabsRenderer';
import { ProductInfoRenderer } from './ProductInfoRenderer';
import { SliderRenderer } from './SliderRenderer';
import { CombinationProductsRenderer } from './CombinationProductsRenderer';
import { productGoogleTracking } from './ProductGoogleTracking';
import {
    pdfTracking,
    recommendationTracking,
    testAboTracking,
    recensionNavClick,
    socialShareButtonTracking,
} from './Tracking/produktdetailtracking';
import { checkForRecaptcha, recaptchaId } from '../Shared/userCentrics';

const trboId = 'ryxCCtrEX';

const produktPrice = new ProduktPrice();
const tabsRenderer = new TabsRenderer();
const productInfoRenderer = new ProductInfoRenderer();
const slider = new SliderRenderer();
const combinationProductsRenderer = new CombinationProductsRenderer();

$(async () => {
    productInfoRenderer.RenderRelatedProducts();
    tabsRenderer.getGesamtwerkTab();
    tabsRenderer.getAutorenInfoTab();
    tabsRenderer.getRezensionenTab();
    combinationProductsRenderer.getCombinationProducts();
    slider.getProductFamily();
    await slider.getTrboProducts();
    await slider.getTrboLastSeenProducts();
    await productGoogleTracking();

    /**
     * Event wenn Prduktdetailstext zu lang ist
     */
    if ($('.details.description').height() < 340) {
        $('.show-more1 a').hide();
    }
    /**
     * Event wenn auf "mehr" button gedrückt wird
     */
    $('.show-more1 a').on('click', function() {
        var $this = $(this);
        var $content = $('.produktbeschreibung');
        var linkText = $this.text();

        if (linkText === 'Mehr anzeigen') {
            linkText = 'Weniger anzeigen';
            $content.removeClass('hide-produktbeschreibung');
            $content.addClass('show-produktbeschreibung');
        } else {
            linkText = 'Mehr anzeigen';
            $content.removeClass('show-produktbeschreibung');
            $content.addClass('hide-produktbeschreibung');

            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $('.description').offset().top,
                },
                300
            );
        }

        $this.text(linkText);
    });

    /**
     * Event wenn "Preisdetails anzeigen" auf Mobile geklickt wird
     */
    $('.priceDetailsMobile').click(e => {
        produktPrice.ShowPriceDetailsMobileClicked(e.currentTarget);
    });

    $('#print_button').click(e => {
        window.print();
    });

    $('body').click(e => {
        $('.social-menu-bubble').addClass('hidden');
        $('.social-menu-bubble').removeClass('active');
    });

    $('.shareButton').click(e => {
        e.preventDefault();
        if ($('.social-menu-bubble').hasClass('active')) {
            $('.social-menu-bubble').addClass('hidden');
            $('.social-menu-bubble').removeClass('active');
            $('.social-menu-bubble').removeClass('active');
        } else {
            $('.social-menu-bubble').removeClass('hidden');
            $('.social-menu-bubble').addClass('active');
            $('.social-menu-bubble').addClass('active');
        }
        e.stopPropagation();
    });

    /**
     * Event wenn der Preis im Dropdown auf Mobile geändert wird
     */
    $('#mobilePriceSelect').change(e => {
        produktPrice.UpdateDisplayedPriceDetailsMobile($(e.currentTarget).val());
    });

    $('#seminar_terminwahl').on('change', function() {
        var engagementId = $(this).val();
        $('.ajax_checkout_add_seminar').attr(
            'href',
            'https://www.beck-seminare.de/warenkorb.aspx?action=add&id=' + engagementId
        );
        var specialConditions = $(this)
            .find(`option[value=${engagementId}]`)
            .data('special-conditions');
        $('.specialConditions').html(specialConditions);
    });

    $('.attachmenttracking').on('click', function() {
        let productId = +$(this).attr('data-productid');
        let at = $(this).attr('data-attachementtype');
        pdfTracking(productId, at);
    });

    $('.probeabotracking').on('click', function() {
        const price = Number.parseFloat($(this).attr('data-price'));
        let productId = +$(this).attr('data-productid');

        testAboTracking(productId, price);
    });

    $('.empfehlungtracking').on('click', function() {
        let productId = +$(this).attr('data-productid');
        recommendationTracking(productId);
    });

    $('.socialShareTracking').on('click', function() {
        const service = $(this).attr('data-social');
        socialShareButtonTracking(service);
    });

    $('.rezensionen-nav').click(function() {
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: $('#infoTabs').offset().top,
            },
            200
        );
        let productId = +$(this).attr('data-productid');
        recensionNavClick(productId);
        $('#infoTabs .rezensionenSwitcher a').click();
    });
});
